﻿@use 'sass:math';
@import 'variables';
@import 'mixins';

/*Enable functionality by overriding this:*/
body.publish-mode {
	aside.toolbar-layout-selector .separator-tab {
		display: none;
	}

	.context-menu-item.grouping-filter {
		display: none !important;
	}
} 

/* Look and feel */

$separatorBackground: #013A65;

.separator-element {
	width: 100% !important; //Important to make sure show()/hide()-anim doesn't change width
	font-size: 20px;
	background: $separatorBackground;
	color: white;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 10px;

	ul {
		margin: 5px 5px 5px 5px;
		padding: 0px;

		li {
			list-style-type: none;
			display: inline-block;
			margin-right: 5px;

			&::after {
				margin-left: 5px;
				content: '-';
			}

			&:last-child::after {
				content: '';
			}
		}
	}

	h2 {
		margin: 0px;
		font-size: 18px;
		font-weight: normal;
		letter-spacing: 0.45px;
	}

	h3 {
		margin: 0px;
		font-size: 16px;
	}

	h4 {
		margin: 0px;
		font-size: 14px;
	}

	h5 {
		margin: 0px;
		font-size: 12px;
	}

	.exp-toggler {
		cursor: pointer;
		margin: 0px;

		&[aria-expanded='true'] {
			.glyphicon-chevron-down {
				display: none;
			}
		}

		&[aria-expanded='false'] {
			.glyphicon-chevron-up {
				display: none;
			}
		}
	}

	.d4-listing-row {
		border-top: none;
		padding-top: 0px;
	}

	.action-elements {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.exp-toggler {
		font-size: 16px;
	}

	&:not(.variant-separator) ~ .WebPubElement {
		background: transparent;
	}

	&.variant-separator {
		background: #F5FCFF;
		border-top: 1px solid #222;
		color: #222;
		padding: 0px;

		.d4-listing-row {
			margin: 5px;

			@include fromSizeSM() {
				margin: 10px 15px;
			}

			.image-cell {
				min-width: 60px;

				@include fromSizeSM() {
					min-width: 110px;
				}
			}
		}

		.desc1 {
			font-weight: bold;
		}

		.desc2 {
			font-size: 16px;
		}
	}

	~ .WebPubElement {
		margin-bottom: 0px;
		width: 100% !important; //Important to make sure show()/hide()-anim doesn't change width
		max-width: 100% !important;
	}

	&.isColl ~ .WebPubElement {
	}
}

.is-var {
	display: none;
}
.web-pub-field.grouped .is-var {
	& + .d4-row {
		background: #F8FDFF;
	}
}

.separator-element:nth-child(n+2) h1 {
	border-top: 1px solid #b6b2b2;
}

.grouped .ProduktImg {
	margin: 0;

	img {
		max-width: 60px;
		height: auto;

		@include fromSizeSM() {
			max-width: 80px;
		}
	}
}

/* END Look and feel */


/* Layout-structure */
/* D4 Fluid layout*/
$margin: 10px;
$maxWidth: 3840px;
$elementWidth: 300px;
$maxColumns: floor(math.div($maxWidth, $elementWidth));
$leftSideWidth: 250px;
$maxWidth: $elementWidth * $maxColumns;

.grouped.layout-d4_fluid {
	> .row .inner-layout-container {
		display: grid;
		grid-gap: $margin $margin;

		> .WebPubElement,
		.slides_container .WebPubElement {
			margin: 0px 0px 0px 0px;
			width: auto;
			grid-column-end: span 1;
			grid-row-end: span 1;

			.D4Standard {
				height: 100%;
			}
		}
	}
}

@for $i from 1 through $maxColumns {
	@media (min-width: ($elementWidth * $i)) {
		.grouped.layout-d4_fluid > .row .inner-layout-container {
			grid-template-columns: repeat($i, 1fr);

			.separator-element {
				grid-column: 1 / span $i;
			}
		}
	}
}
/* D4 Static column-layouts */
.grouped {
	&.layout-d4_x2,
	&.layout-d4_x3,
	&.layout-d4_x4,
	&.layout-d4_x5,
	&.layout-d4_x6,
	&.layout-d4_x7 {
		& > .row .inner-layout-container {
			display: grid;
			grid-gap: $margin $margin;
			/* margin-bottom: 10px; */

			.WebPubElement {
				margin: 0px 0px 0px 0px;
				width: auto;
				grid-column-end: span 1;
				grid-row-end: span 1;

				.D4Standard {
					height: 100%;
				}
			}
		}
	}
}

$maxColumnNumber: 7;

@for $colNumber from 2 through $maxColumns {
	.grouped.layout-d4_x#{$colNumber} {
		& > .row .inner-layout-container {
			grid-template-columns: repeat($colNumber, 1fr);

			.separator-element {
				grid-column: 1 / span $colNumber;
			}
		}
	}
}
/* END Layout-structure */
